import { get } from '../bv/local_storage';
import { getCookie } from '../bv/cookies';

export const isAccountNumberSet = () => !!getCookie('_vc_l') && !(getCookie('_vc_l') === 'false');
export const loggedIn = () => isAccountNumberSet() && !get('geoposition_on_login');
export const accountNumber = () => getCookie('_vc_l');
export const accountId = () => getCookie('_vc_i');
export const countryCode = () => getCookie('country_code');
export const licenceId = () => getCookie('l_id');
export const originId = () => window.VCSTATE.ORIGIN_ID;
