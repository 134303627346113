/* eslint no-param-reassign: "off" */
import { compose, combineReducers } from 'redux';

import store from './store';
import routingReducer, { refreshCurrentPath } from './ducks/routing';
import sessionReducer, { refreshLoggedIn } from './ducks/session';
import appReducer, { setVersion } from './ducks/app';

// Globally available redux selectors
const injectAsyncReducers = (asyncReducers) => {
  store.reducers = { ...store.reducers, ...asyncReducers };

  store.replaceReducer(combineReducers(store.reducers));
};

const addActionDispatchers = (namespace, actions) => {
  Object.entries(actions).forEach(([key, value]) => {
    store.actionDispatchers[namespace] = store.actionDispatchers[namespace] || {};
    store.actionDispatchers[namespace][key] = compose(store.dispatch, value);
  });
};

store.reducers = {};

store.actionDispatchers = {};

window.reduxActionDispatchers = store.actionDispatchers;

const reduxState = {
  store,
  addReducers: injectAsyncReducers,
  addActionDispatchers,
};

reduxState.addReducers({
  routing: routingReducer,
  session: sessionReducer,
  app: appReducer,
});

reduxState.addActionDispatchers('routing', { refreshCurrentPath });
reduxState.addActionDispatchers('session', { refreshLoggedIn });
reduxState.addActionDispatchers('app', { setVersion });

window.reduxState = reduxState;

export { store };
